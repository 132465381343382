import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Button, Select, MenuItem, Typography, Box, TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';



function ChildFollow() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { email } = useParams();

  const [childData, setChildData] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [periodicity, setPeriodicity] = useState("day");
  const [view, setView] = useState('stats');
const [data, setData] = useState([]);
const [totalRequests, setTotalRequests] = useState(0);
const [connectionRate, setConnectionRate] = useState(0);
const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  useEffect(() => {
    async function fetchData() {
      try {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();

        const apiName = 'miamentor-usersinfo';
        const path = `/getchildlogs`;

        const myInit = {
          body: {
            email: email
          },
          headers: {
            Authorization: jwtToken
          }
        };

        const result = await API.post(apiName, path, myInit);
        console.log(result);

        setChildData(result);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de l'enfant:", error);
        enqueueSnackbar(t('errorFetchingChild'), { variant: 'error' });
      }
    }
    fetchData();
  }, [email, enqueueSnackbar, t]);

  useEffect(() => {
    if (childData) {
      calculateStats();
    }
  }, [childData, startDate, endDate, periodicity]);

  const calculateStats = () => {
    // Votre logique pour calculer totalRequests, connectionRate, et pour préparer les données pour le graphique
    // ...

    const filteredLogs = childData.logs.filter(log => {
        const logDate = new Date(log.date);
        return logDate >= startDate && logDate <= endDate;
      });
      
      // Nombre total de requêtes
      const totalReqs = filteredLogs.reduce((sum, log) => sum + log.requestCount, 0);
      setTotalRequests(totalReqs);
    
      // Calculer le nombre total de jours dans la plage de dates
      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1; // +1 pour inclure à la fois la startDate et la endDate
      
      // Calculer le nombre de jours uniques où l'utilisateur s'est connecté
      const uniqueConnectionDays = new Set(filteredLogs.map(log => log.date)).size;
    
      // Calculer le taux de connexion
      const connRate = (uniqueConnectionDays / totalDays) * 100;
      setConnectionRate(Math.round(connRate));

    // Exemple : si periodicity est 'day'
    if (periodicity === 'day') {
      const filteredLogs = childData.logs.filter(log => {
        const logDate = new Date(log.date);
        return logDate >= startDate && logDate <= endDate;
      });
      
      setData(filteredLogs.map(log => ({
        date: log.date,
        requests: log.requestCount
      })));

      // Continuez avec les autres calculs...
    }

    if (periodicity === 'month') {
        const groupedByMonth = {};
      
        childData.logs.forEach(log => {
          const logDate = new Date(log.date);
          if (logDate >= startDate && logDate <= endDate) {
            const monthYearKey = `${logDate.getMonth() + 1}-${logDate.getFullYear()}`;
            if (!groupedByMonth[monthYearKey]) {
              groupedByMonth[monthYearKey] = {
                date: monthYearKey,
                requests: 0
              };
            }
            groupedByMonth[monthYearKey].requests += log.requestCount;
          }
        });
      
        setData(Object.values(groupedByMonth));
      }

      if (periodicity === 'week') {
        const groupedByWeek = {};
      
        childData.logs.forEach(log => {
          const logDate = new Date(log.date);
          if (logDate >= startDate && logDate <= endDate) {
            // Calculer le premier jour de la semaine pour le log
            const startOfWeek = new Date(logDate);
            startOfWeek.setDate(logDate.getDate() - logDate.getDay()); // Utiliser getDay() pour obtenir le jour de la semaine (0 pour Dimanche, 1 pour Lundi, etc.)
      
            const weekKey = `${startOfWeek.getDate()}-${startOfWeek.getMonth() + 1}-${startOfWeek.getFullYear()}`; // Format "jour-mois-année" pour le premier jour de la semaine
            if (!groupedByWeek[weekKey]) {
              groupedByWeek[weekKey] = {
                date: weekKey,
                requests: 0
              };
            }
            groupedByWeek[weekKey].requests += log.requestCount;
          }
        });
      
        setData(Object.values(groupedByWeek));
      }

      if (periodicity === 'year') {
        const groupedByYear = {};
      
        childData.logs.forEach(log => {
          const logDate = new Date(log.date);
          if (logDate >= startDate && logDate <= endDate) {
            const yearKey = `${logDate.getFullYear()}`;
            if (!groupedByYear[yearKey]) {
              groupedByYear[yearKey] = {
                date: yearKey,
                requests: 0
              };
            }
            groupedByYear[yearKey].requests += log.requestCount;
          }
        });
      
        setData(Object.values(groupedByYear));
      }
    // ...
  };

  const renderStats = () => {
    return (
      <div style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>{t('childfollow.statistics')}</Typography>
        
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('childfollow.startDate')}
              value={startDate}
              onChange={setStartDate}
              textField={(props) => <TextField {...props} variant="outlined" margin="normal" />}
            />
            <DatePicker
              label={t('childfollow.endDate')}
              value={endDate}
              onChange={setEndDate}
              textField={(props) => <TextField {...props} variant="outlined" margin="normal" />}
            />
          </LocalizationProvider>
          <Select 
            value={periodicity} 
            onChange={e => setPeriodicity(e.target.value)}
            variant="outlined"
            style={{ minWidth: '150px' }}
          >
            <MenuItem value="day">{t('childfollow.day')}</MenuItem>
            <MenuItem value="week">{t('childfollow.week')}</MenuItem>
            <MenuItem value="month">{t('childfollow.month')}</MenuItem>
          </Select>
        </Box>

        <Typography variant="h6" gutterBottom>
        {t('childfollow.totalRequests')} {totalRequests} 
        </Typography>
        <Typography variant="h6" gutterBottom style={{ marginBottom: '20px' }}>
        {t('childfollow.connectionRate')} {connectionRate}%
        </Typography>
        
        <Box marginBottom={4} style={{ background: '#f5f5f5', padding: '20px', borderRadius: '10px' }}>
          <ResponsiveContainer width='100%' height={400}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="requests" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Box>

        <Box sx={{ height: 400, width: '100%', background: '#f5f5f5', padding: '20px', borderRadius: '10px' }}>
          <DataGrid
            getRowId={(row) => row.date}
            rows={data}
            columns={[
                { field: 'date', headerName: t('childfollow.columns.date'), width: 130 },
                { field: 'requests', headerName: t('childfollow.columns.requests'), width: 130 }
              ]}
            pageSize={5}
            rowsPerPageOptions={[5]}
            pagination
          />
        </Box>
      </div>
    );
};
const byteArrayToString = (byteArray) => {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(new Uint8Array(byteArray));
};

const getChildFirstName = (email) => {
    const childs = JSON.parse(sessionStorage.getItem('childs') || '[]');
    const child = childs.find(c => c.email === email);
    return child ? child.firstName : "user";
  };
  
  const renderHistory = () => {
    if (!childData) return null;
  
    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
  
    const conversationsForSelectedDate = childData.conversations.find(conv => 
      new Date(conv.LastModified).toDateString() === selectedDate
    );
  
    let conversationDisplay = null;
    if (conversationsForSelectedDate && conversationsForSelectedDate.Body) {
      const bodyString = byteArrayToString(conversationsForSelectedDate.Body.data);
      const childFirstName = getChildFirstName(childData.email); 
  
      try {
        const bodyJson = JSON.parse(bodyString);
        if (bodyJson.conversation && Array.isArray(bodyJson.conversation)) {
            conversationDisplay = bodyJson.conversation.map((entry, index) => {
                let senderName = entry.sender === 'user' ? childFirstName : 'Mia Mentor';
                return (
                    <div key={index} style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }}>
                        <strong>{senderName}:</strong>
                        <p style={{ whiteSpace: 'pre-line' }}>{entry.content}</p>
                    </div>
                );
            });
        }
      } catch (e) {
        conversationDisplay = <p>Error parsing data</p>;
      }
    }
  
    return (
      <div style={{ padding: '16px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h6">{t('childfollow.history')}</Typography>
        <Select value={selectedDate} onChange={handleDateChange} style={{ margin: '16px 0' }}>
          {childData.conversations.map(conv => (
            <MenuItem key={conv.LastModified} value={new Date(conv.LastModified).toDateString()}>
              {new Date(conv.LastModified).toDateString()}
            </MenuItem>
          ))}
        </Select>
        <div style={{ background: '#fafafa', borderRadius: '8px', padding: '8px' }}>
          {conversationDisplay}
        </div>
      </div>
    );
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Button 
          variant="contained" 
          color={view === 'stats' ? "primary" : "secondary"} 
          onClick={() => setView('stats')}
          style={{ margin: '10px', padding: '10px 20px', boxShadow: '3px 3px 5px rgba(0,0,0,0.2)' }}
        >
          {t('childfollow.statisticsButton')}
        </Button>
        <Button 
          variant="contained" 
          color={view === 'history' ? "primary" : "secondary"} 
          onClick={() => setView('history')}
          style={{ margin: '10px', padding: '10px 20px', boxShadow: '3px 3px 5px rgba(0,0,0,0.2)' }}
        >
          {t('childfollow.historyButton')}
        </Button>
      </div>
      <div>
        {view === 'stats' ? renderStats() : renderHistory()}
      </div>
    </div>
  );
}

export default ChildFollow;
