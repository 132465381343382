import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Grid, Typography, Button, Avatar, Select, MenuItem,
  FormControl, InputLabel, Switch, FormControlLabel, TextField, useTheme, Box } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import ColorModeContext from '../ColorModeContext';
import countries from 'i18n-iso-countries';



function Account({ fetchUserData }) {

  // Récupération des données de l'utilisateur à partir de sessionStorage
  const storedUser = JSON.parse(sessionStorage.getItem('user') || '{}');
  const { t, i18n } = useTranslation();
  const primaryLanguage = i18n.language.split('-')[0];
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
  const countryList = countries.getNames(primaryLanguage);

  const [formData, setFormData] = useState({
      firstName: storedUser.firstName || '',
      lastName: storedUser.lastName || '',
      birthDate: storedUser.dateOfBirth || '',
      country: storedUser.country || '',
      language: storedUser.language || ''
  });

  const [selectedAvatar, setSelectedAvatar] = useState(storedUser.avatar || '');
  const [uploadedAvatar, setUploadedAvatar] = useState('');

  const defaultAvatars = [
    '/avatars/1.png',
    '/avatars/2.png',
    '/avatars/3.png',
    '/avatars/4.png',
    '/avatars/5.png',
    '/avatars/6.png',
    '/avatars/7.png',
    '/avatars/8.png',
    '/avatars/9.png',
    // ... autres URLs
    ];

    const { toggleColorMode } = React.useContext(ColorModeContext);
    const theme = useTheme();

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAvatarUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
              setUploadedAvatar(reader.result);
          };
          reader.readAsDataURL(file);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // L'objet que vous allez envoyer via l'API
    const dataToSend = {
        ...formData,
        darkMode: theme.palette.mode,
        uploadedAvatar: uploadedAvatar,
        selectedAvatar: selectedAvatar
    };

    try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          //console.log(dataToSend);
            const jwtToken = user.signInUserSession.idToken.jwtToken;
            
            const response = await API.post('miamentor-usersinfo', '/updateparentaccount', {
                headers: {
                    'Authorization': jwtToken
                },
                body: dataToSend
            });

            if (response) {
                console.log(response);
                fetchUserData();
                //enqueueSnackbar(t('addchildrenformsubmit.success'), { variant: 'success' });
                // Réinitialisation du formulaire
                //onUpdateChilds();
                //resetForm();
            } else {
                //console.log(response);
                //enqueueSnackbar(t('addchildrenformsubmit.responseerror'), { variant: 'error' });
            }
        }
    } catch (error) {
        //enqueueSnackbar(t('addchildrenformsubmit.error'), { variant: 'error' });
        console.error('Erreur lors de la soumission du formulaire:', error);
    }
  };

  return (
      <div>
        <Typography variant="h6">{t('accountsettings.accountsettingstitle')}</Typography>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                      <TextField
                          fullWidth
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          placeholder={t('addchildrenform.firstname')}
                          margin="normal"
                      />
                      <TextField
                          fullWidth
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          placeholder={t('addchildrenform.lastname')}
                          margin="normal"
                      />
                      <TextField
                          fullWidth
                          type="date"
                          name="birthDate"
                          value={formData.birthDate}
                          onChange={handleChange}
                          margin="normal"
                      />
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="country-label">{t('addchildrenform.country')}</InputLabel>
                        <Select
                            labelId="country-label"
                            id="country"
                            value={formData.country}
                            onChange={handleChange}
                        >
                            {Object.entries(countryList).map(([code, name]) => (
                                <MenuItem key={code} value={code}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Ajout d'un espace ici */}
                    <Box mt={2}>
                        <FormControl fullWidth>
                            <InputLabel>{t('addchildrenform.language')}</InputLabel>
                            <Select
                                value={formData.language}
                                onChange={handleChange}
                                name="language"
                            >
                                <MenuItem value="en">{t('addchildrenform.english')}</MenuItem>
                                <MenuItem value="fr">{t('addchildrenform.french')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                      <Typography variant="h6">{t('addchildrenform.chooseavatar')}</Typography>
                      <Grid container spacing={2}>
                          {defaultAvatars.map((avatar, index) => (
                              <Grid item key={index}>
                                  <Avatar 
                                      src={avatar} 
                                      onClick={() => setSelectedAvatar(avatar)}
                                      style={{ cursor: 'pointer', border: selectedAvatar === avatar ? '2px solid blue' : 'none' }}
                                  />
                              </Grid>
                          ))}
                      </Grid>

                      <Typography variant="h6" gutterBottom>{t('addchildrenform.uploadavatar')}</Typography>
                      <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                              <Button
                                  variant="contained"
                                  component="label"
                                  startIcon={<ImageIcon />}
                                  margin="normal"
                              >
                                  {t('addchildrenform.upload')}
                                  <input
                                      type="file"
                                      accept="image/*"
                                      hidden
                                      onChange={handleAvatarUpload}
                                      margin="normal"
                                  />
                              </Button>
                          </Grid>
                          {uploadedAvatar && (
                              <Grid item>
                                  <Avatar src={uploadedAvatar} />
                              </Grid>
                          )}
                      </Grid>
                      <Box marginTop={2}>
                      <FormControlLabel 
                          control={
                            <Switch 
                            checked={theme.palette.mode === 'dark'} 
                            onChange={toggleColorMode} 
                            color="primary"
                            
                          />
                          }
                          label={t('accountsettings.darkMode')}
                      />
                      </Box>
                  </Grid>

                  <Grid item xs={12}>
                      <Button variant="contained" color="primary" type="submit">
                          {t('accountsettings.update')}
                      </Button>
                  </Grid>
              </Grid>
          </form>
      </div>
  );
}

export default Account;