import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Paper, TextareaAutosize, IconButton, Tooltip, Button } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CopyIcon from '@mui/icons-material/FileCopy';
import { API } from 'aws-amplify';
import { CircularProgress } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';

function MiaMentor() {
  const [messages, setMessages] = useState([  ]);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const uploadedAvatar = user ? user.uploadedAvatar : null;
  const selectedAvatar = user ? user.selectedAvatar : null;
  const hasLicence = user ? user.license : false;
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef();
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customColors, setCustomColors] = useState({
    userBubble: '#e0f7fa',
    aiBubble: '#c5e1a5',
    userText: '#000000',
    aiText: '#000000',
  });

  const [isListening, setIsListening] = useState(false);
  const [message, setMessage] = useState('');

  let recognition = null;
  if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
    recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    const user = sessionStorage.getItem('user');
    if (user) {
      const { language } = JSON.parse(user);
      // Définition de la langue de reconnaissance en fonction de la valeur récupérée
      recognition.lang = language === 'fr' ? 'fr-FR' : 'en-US';
    } else {
      // Langue par défaut si aucune valeur n'est stockée
      recognition.lang = 'en-US';
    }
    recognition.interimResults = true;
    recognition.timeout = 5;
    
    recognition.onresult = (event) => {
      for (const res of event.results) {
        if (res.isFinal) {
          setMessage(prevMessage => prevMessage + res[0].transcript);
        }
      }
    };
    
    recognition.onend = () => {
      setIsListening(false);
    };
  }

  const handleMicClick = () => {
    if (recognition) {
      setIsListening(!isListening);
      if (!isListening) {
        recognition.start();
      } else {
        recognition.stop();
      }
    } else {
      enqueueSnackbar(t('miamentorpage.speecherror'), { variant: 'error' });
      console.error(t('miamentorpage.speecherror'));
    }
  };

  const handleSendMessage = async () => {
    if (messageInputRef.current) {
      const message = messageInputRef.current.value;
      if (message.trim()) {
        try {
          setIsLoadingResponse(true);
          // Ajout du message de l'utilisateur à l'affichage
          setMessages(prevMessages => [...prevMessages, { sender: 'user', content: message }]);
          messageInputRef.current.value = '';
  
          // Obtention du token pour l'autorisation
          const user = await Auth.currentAuthenticatedUser();
          const jwtToken = user.signInUserSession.idToken.jwtToken;
  
          // Appel de l'API Gateway pour envoyer le message à la fonction Lambda
          const response = await API.post('miamentor-usersinfo', '/aichatforchild', {
            headers: {
              'Authorization': jwtToken,
            },
            body: {
              message,
            }
          });
          setMessage('');
          console.log(response);
          // Ajout de la réponse du bot à l'affichage
          setIsLoadingResponse(false);
          setMessages(prevMessages => [...prevMessages, { sender: 'bot', content: response.message }]);
        } catch (error) {
          enqueueSnackbar(t('miamentorpage.sendmessageerror'), { variant: 'error' });
          console.error(t('miamentorpage.sendmessageerror'), error);
          setIsLoadingResponse(false);
        }
      }
    }
  };
  

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    async function fetchMessages() {
      try {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          //console.log(dataToSend);
            const jwtToken = user.signInUserSession.idToken.jwtToken;
            //console.log(jwtToken);
            const response = await API.get('miamentor-usersinfo', '/ialasthistory', {
                headers: {
                    'Authorization': jwtToken
                }
            });
            if(!response.conversation){
              setMessages([{ sender: 'bot', content: t('miamentorpage.iadefaultmessage') }]);
            }else {
            setMessages(response.conversation);
            //console.log(response);
          }
          }
        
      } catch (error) {
        enqueueSnackbar(t('miamentorpage.errormessagehistory'), { variant: 'error' });
        console.error(t('miamentorpage.errormessagehistory'), error);
        // Définir un message par défaut si la récupération échoue
        setMessages([{ sender: 'bot', content: t('miamentorpage.iadefaultmessage') }]);
      } finally {
        setLoading(false);
      }
    }
    const userData = sessionStorage.getItem('user');
    if (userData) {
      const userObj = JSON.parse(userData);
      if (userObj.customColors) {
        setCustomColors(userObj.customColors);
      }
    }
    fetchMessages();


  }, []);

  return (
    <Box p={3} display="flex" flexDirection="column" height="calc(100vh - 120px)">
      {hasLicence ? (
      <><Paper elevation={3} style={{ flexGrow: 1, overflow: 'auto', padding: '16px', marginBottom: '16px' }}>

          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {messages.map((message, index) => (
                <Box key={index} mb={2} align={message.sender === 'user' ? 'left' : 'left'}>
                  {message.sender !== 'user' ? (
                    <img src="/miamentor-logo.png" alt="MiaMentor Avatar" style={{ width: '50px', marginRight: '8px' }} />
                  ) : (
                    <img
                      src={uploadedAvatar ? uploadedAvatar : selectedAvatar ? selectedAvatar : '/avatars/6.png'}
                      alt="User Avatar"
                      style={{ width: '50px', marginRight: '8px' }} />
                  )}
                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      display: 'inline-block',
                      padding: '8px',
                      borderRadius: '8px',
                      background: message.sender === 'user' ? customColors.userBubble : customColors.aiBubble,
                      color: message.sender === 'user' ? customColors.userText : customColors.aiText,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: message.content ? message.content.replace(/\n/g, '<br />') : ''
                    }}
                  ></Typography>
                  {message.sender === 'bot' && (
                    <Tooltip title="Copier">
                      <IconButton size="small" style={{ marginLeft: '8px' }}>
                        <CopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              ))}
              {isLoadingResponse && (
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                  <CircularProgress />
                </Box>
              )}
              <div ref={messagesEndRef} />
            </>
          )}
        </Paper><Box display="flex" alignItems="center" mt={2} p={1} bgcolor="#f0f0f0" borderRadius="8px">
            <TextareaAutosize
              ref={messageInputRef}
              minRows={3}
              maxRows={10}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ flexGrow: 1, resize: 'none', padding: '8px', borderRadius: '8px', margin: '8px' }}
              placeholder={t('miamentorpage.textareaplaceholder')} />
            <Tooltip title="Parler">
              <IconButton color="primary" style={{ margin: '8px' }} onClick={handleMicClick}>
                <MicIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              style={{ margin: '8px' }}
            >
              {t('miamentorpage.sendbutton')}
            </Button>
          </Box></>
      ) : (
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center" 
          style={{ height: '100%' }}
        >
          <WarningIcon color="error" style={{ fontSize: 40, marginBottom: 16 }} />
          <Typography variant="h6" color="error" paragraph>
            {t('miamentorpage.licenseerror')}
          </Typography>
        </Box>
        )}
    </Box>
  );
}

export default MiaMentor;
