import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(Backend) // Charger les traductions à partir d'un serveur distant ou de fichiers locaux
  .use(LanguageDetector) // Détecte la langue de l'utilisateur
  .use(initReactI18next) // Intègre i18next à React
  .init({
    fallbackLng: 'en',
    debug: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',  // ceci détermine le chemin de chargement
    },
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
    react: { 
        useSuspense: false //   <---- this will do the magic
      }
  });

export default i18n;