import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Drawer, Button, Checkbox, FormControl, FormControlLabel, InputLabel,
  MenuItem, Select, TextField, Typography, Tooltip, Divider, Grid, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Avatar, Card, CardContent, IconButton
   } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import ImageIcon from '@mui/icons-material/Image';
import { useSnackbar } from 'notistack';
import countries from 'i18n-iso-countries';


function ChildForm({ initialValues, onUpdateChilds }) {
    //Form varibles
    const [personalEmail, setPersonalEmail] = useState(initialValues?.personalEmail || false);
    const [email, setEmail] = useState(initialValues?.email || '');
    const [autoGeneratePassword, setAutoGeneratePassword] = useState(true);
    const [schoolSystem, setSchoolSystem] = useState(initialValues?.schoolSystem || '');
    const [schoolClass, setSchoolClass] = useState(initialValues?.schoolClass || '');
    const [selectedSubjects, setSelectedSubjects] = useState(initialValues?.selectedSubjects || []);
    const [handicapSituation, setHandicapSituation] = useState(initialValues?.handicapSituation || false);
    const [selectedHobbies, setSelectedHobbies] = useState(initialValues?.selectedHobbies || []);
    const [otherHobbies, setOtherHobbies] = useState(initialValues?.otherHobbies || "");
    const [lastName, setLastName] = useState(initialValues?.lastName || "");
    const [firstName, setFirstName] = useState(initialValues?.firstName || "");
    const [dateOfBirth, setDateOfBirth] = useState(initialValues?.dateOfBirth || null);
    const [gender, setGender] = useState(initialValues?.gender || "");
    const [disabilityDescription, setDisabilityDescription] = useState(initialValues?.disabilityDescription || "");
    const [pseudo, setPseudo] = useState(initialValues?.pseudo || "");
    const [checkedObjectives, setCheckedObjectives] = useState(initialValues?.checkedObjectives || []);
    const [comments, setComments] = useState(initialValues?.comments || "");
    const [password, setPassword] = useState(initialValues?.password || "");
    const [passwordError, setPasswordError] = useState(initialValues?.passwordError || "");
    const [uploadedAvatar, setUploadedAvatar] = useState(initialValues?.uploadedAvatar || null);
    const [allowAvatarModification, setAllowAvatarModification] = useState(initialValues?.allowAvatarModification || false);
    const [country, setCountry] = useState(initialValues?.country || '');
    const [language, setLanguage] = useState(initialValues?.language || '');

    //Code Management Variables
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState(null);
    const [emailError, setEmailError] = useState('');
    const { t, i18n } = useTranslation();
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

    const handleObjectiveChange = (event) => {
        const value = event.target.name; 
        if (event.target.checked) {
            setCheckedObjectives(prev => [...prev, value]);
        } else {
            setCheckedObjectives(prev => prev.filter(item => item !== value));
        }
    };
  
  const defaultAvatars = [
    '/avatars/1.png',
    '/avatars/2.png',
    '/avatars/3.png',
    '/avatars/4.png',
    '/avatars/5.png',
    '/avatars/6.png',
    '/avatars/7.png',
    '/avatars/8.png',
    '/avatars/9.png',
    // ... autres URLs
    ];
    const [selectedAvatar, setSelectedAvatar] = useState(defaultAvatars[0]);

    const handleAvatarUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedAvatar(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

  const handleLoisirsChange = (event) => {
    const value = event.target.name; 
    if (event.target.checked) {
        setSelectedHobbies(prev => [...prev, value]);
    } else {
        setSelectedHobbies(prev => prev.filter(item => item !== value));
    }
};

function validatePassword(password) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return regex.test(password);
  }

  const handleSubjectsChange = (event) => {
    setSelectedSubjects(event.target.value);
  };

  const handleIconClick = (content) => {
    setDrawerContent(content);
    setDrawerOpen(true);
};

// Handler pour fermer le tiroir
const handleCloseDrawer = () => {
    setDrawerOpen(false);
};

const checkEmailUniqueness = async (email) => {
  const code = '000000'
  console.log(email);
  try {
    await Auth.confirmSignUp(email, code, {
        forceAliasCreation: false
    });
    // Si vous atteignez ce point, l'email peut ne pas être unique (en fonction de la logique de votre application)
    return false;
} catch (err) {
    console.log(err);
    switch (err.code) {
        case 'UserNotFoundException':
            return true;
        case 'NotAuthorizedException':
            return false;
        case 'AliasExistsException':
            return false;
        case 'CodeMismatchException':
            return false;
        case 'ExpiredCodeException':
            return false;
        default:
            return false;
    }
}
};

const checkEmailInCognito = async (e) => {
  const email = e.target.value;
  const isUnique = await checkEmailUniqueness(email); // Implémentez cette fonction pour interroger Cognito
  console.log(isUnique)
  if (!isUnique) {
    setEmailError(t('mailalreadyused'));
  } else {
      setEmailError('');  // Clear any previous error
  }
}

const checkGeneratedEmailInCognito = async (e) => {
    const inputValue = e.target.value;
  
    // Vérifier si l'entrée est compatible avec une adresse email
    const isValidEmailPrefix = /^[a-z0-9.-]+$/g.test(inputValue);
  
    if (!isValidEmailPrefix) {
      setEmailError(t('invalidemailformat')); // Assurez-vous d'ajouter une clé 'invalidemailformat' dans vos traductions si vous utilisez i18n.
      return; // Si l'entrée n'est pas valide, quittez la fonction
    }
  
    const email = inputValue + "@miamentor.com";
    const isUnique = await checkEmailUniqueness(email);
  
    if (!isUnique) {
      setEmailError(t('pseudoalreadyused'));
    } else {
      setEmailError('');  // Clear any previous error
    }
  }
  
  // Les classes en fonction du système scolaire
  const classes = {
      "french": ["6ème", "5ème", "4ème", "3ème", "Seconde", "Première", "Terminale"],
      "US": ["Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"],
      "UK": ["Year 7", "Year 8", "Year 9", "Year 10", "Year 11", "Year 12", "Year 13"],
      "belgium": ["6ème primaire", "1ère secondaire", "2ème secondaire", "3ème secondaire", "4ème secondaire", "5ème secondaire", "6ème secondaire"],
      // ...
  };
  const infoContents = {
    "disability": (
        <div>
            <h4>{t('disability-maintitle')}</h4>
            <p>{t('disability-p1')}</p>
            <p>{t('disability-p2')}</p>
            <h5>{t('disability-subt1')}</h5>
            <p>{t('disability-p3')}</p>
            <p>{t('disability-p4')}</p>
            <h5>{t('disability-subt2')}</h5>
            <p>{t('disability-p5')}</p>
            <p>{t('disability-p6')}</p>
            <h5>{t('disability-subt3')}</h5>
            <p>{t('disability-p7')}</p>
            <p>{t('disability-p8')}</p>
        </div>
    ),    
    "scolarship": (
      <div>
            <h4>{t('scolarship-maintitle')}</h4>
            <p>{t('scolarship-p1')}</p>
            <p>{t('scolarship-p2')}</p>
            <h5>{t('scolarship-subt1')}</h5>
            <p>{t('scolarship-p3')}</p>
            <p>{t('scolarship-p4')}</p>
            <h5>{t('scolarship-subt2')}</h5>
            <p>{t('scolarship-p5')}</p>
            <p>{t('scolarship-p6')}</p>
            <h5>{t('scolarship-subt3')}</h5>
            <p>{t('scolarship-p7')}</p>
            <p>{t('scolarship-p8')}</p>
      </div>

    ),
    "other": (
      <div>
            <h4>{t('other-maintitle')}</h4>
            <p>{t('other-p1')}</p>
            <p>{t('other-p2')}</p>
            <h5>{t('other-subt1')}</h5>
            <p>{t('other-p3')}</p>
            <p>{t('other-p4')}</p>
            <h5>{t('other-subt2')}</h5>
            <p>{t('other-p5')}</p>
            <p>{t('other-p6')}</p>
            <h5>{t('other-subt3')}</h5>
            <p>{t('other-p7')}</p>
            <p>{t('other-p8')}</p>
      </div>
    ),
    "account": (
      <div>
            <h4>{t('account-maintitle')}</h4>
            <p>{t('account-p1')}</p>
            <p>{t('account-p2')}</p>
            <h5>{t('account-subt1')}</h5>
            <p>{t('account-p3')}</p>
            <p>{t('account-p4')}</p>
            <h5>{t('account-subt2')}</h5>
            <p>{t('account-p5')}</p>
            <p>{t('account-p6')}</p>
            <h5>{t('account-subt3')}</h5>
            <p>{t('account-p7')}</p>
            <p>{t('account-p8')}</p>   
      </div>
    )
};
  // Matières en fonction de la classe
  const subjects = {
    "6ème": [
      "Allemand",
      "Anglais",
      "Art plastiques",
      "Education Physique et Sportive (E.P.S)",
      "Espagnol",
      "Français",
      "Histoire - Géographie",
      "Education Civique",
      "Latin",
      "Mathèmatiques",
      "Musique",
      "Physique - Chimie",
      "Sciences et Vie de la Terre (S.V.T)",
      "Technologie"
    ],
    "Seconde": [
      "Allemand",
      "Anglais",
      "Arts",
      "Education Physique et Sportive (E.P.S)",
      "Espagnol",
      "Français",
      "Histoire - Géographie",
      "Education Civique",
      "Latin",
      "Mathèmatiques",
      "Musique",
      "Physique - Chimie",
      "Sciences et Vie de la Terre (S.V.T)",
      "Philosophie",
      "Sciences Economiques et Sociales",
      "Numériques et sciences informatiques",
      "Géopolitique et Sciences Politiques",
      "Humanité, Littérature et Philosophie",
      "Langues et cultures étrangères",
      "Langues et cultures de l'Antiquité",
      "Biologie-écologie",
      "Sciences de l'ingénieur"
    ],
    "Grade 6": ["Math", "English Language Arts", "Science", "Social Studies", "Physical Education", "Art", "Music", "Foreign Language"],
  "Grade 7": ["Math", "English Language Arts", "Science", "Social Studies", "Physical Education", "Art", "Music", "Foreign Language"],
  "Grade 8": ["Math", "English Language Arts", "Science", "Social Studies", "Physical Education", "Art", "Music", "Foreign Language"],
  "Grade 9": ["Math", "English", "Biology", "World History", "Physical Education", "Art", "Music", "Foreign Language", "Electives"],
  "Grade 10": ["Math", "English", "Chemistry", "US History", "Physical Education", "Art", "Music", "Foreign Language", "Electives"],
  "Grade 11": ["Math", "English", "Physics", "US Government", "Physical Education", "Art", "Music", "Foreign Language", "Electives"],
  "Grade 12": ["Math", "English", "Environmental Science", "Economics", "Physical Education", "Art", "Music", "Foreign Language", "Electives"],

  // Royaume-Uni
  "Year 7": ["Math", "English", "Science", "History", "Geography", "Art", "Music", "Physical Education", "Modern Foreign Languages", "Design & Technology", "Religious Education"],
  "Year 8": ["Math", "English", "Science", "History", "Geography", "Art", "Music", "Physical Education", "Modern Foreign Languages", "Design & Technology", "Religious Education"],
  "Year 9": ["Math", "English", "Science", "History", "Geography", "Art", "Music", "Physical Education", "Modern Foreign Languages", "Design & Technology", "Religious Education"],
  "Year 10": ["GCSE Subjects", "Math", "English", "Science", "Physical Education", "Optional subjects like History, Geography, Art, Music, etc."],
  "Year 11": ["GCSE Subjects", "Math", "English", "Science", "Physical Education", "Optional subjects like History, Geography, Art, Music, etc."],
  "Year 12": ["Math", "English", "Science", "History", "Geography", "Art", "Music", "Physical Education", "Modern Foreign Languages", "Design & Technology", "Religious Education"],
  "Year 13": ["Math", "English", "Science", "History", "Geography", "Art", "Music", "Physical Education", "Modern Foreign Languages", "Design & Technology", "Religious Education"],

  // Belgique
  "6ème primaire": ["Math", "Français", "Sciences", "Etude du milieu", "Éducation physique", "Religion", "Morale", "Arts", "Musique"],
  "1ère secondaire": ["Math", "Français", "Sciences", "Histoire", "Géographie", "Éducation physique", "Religion", "Morale", "Langues étrangères", "Technologie"],
  "2ème secondaire": ["Math", "Français", "Sciences", "Histoire", "Géographie", "Éducation physique", "Religion", "Morale", "Langues étrangères", "Technologie"],
  "3ème secondaire": ["Math", "Français", "Sciences", "Histoire", "Géographie", "Éducation physique", "Religion", "Morale", "Langues étrangères", "Technologie"],
  "4ème secondaire": ["Math", "Français", "Sciences", "Histoire", "Géographie", "Éducation physique", "Religion", "Morale", "Langues étrangères", "Technologie"],
  "5ème secondaire": ["Math", "Français", "Sciences", "Histoire", "Géographie", "Éducation physique", "Religion", "Morale", "Langues étrangères", "Technologie"],
  "6ème secondaire": ["Math", "Français", "Sciences", "Histoire", "Géographie", "Éducation physique", "Religion", "Morale", "Langues étrangères", "Technologie"],
};
  subjects["5ème"] = subjects["6ème"];
  subjects["4ème"] = subjects["6ème"];
  subjects["3ème"] = subjects["6ème"];

  subjects["Première"] = subjects["Seconde"];
  subjects["Terminale"] = subjects["Seconde"];

  const currentLanguage = i18n.language;
  const primaryLanguage = i18n.language.split('-')[0];
  const translations = i18n.getResourceBundle(currentLanguage, 'translation');
  const countryList = countries.getNames(primaryLanguage);
  
  const objectives = Object.keys(translations.objectives).map(key => translations.objectives[key]);
  const loisirsList = Object.keys(translations.loisirsList).map(key => translations.loisirsList[key]);
  const otherTranslation = t(`loisirsList.${Object.keys(translations.loisirsList).length - 1}`);
  
  const handleSubmit = async (event) => {
    event.preventDefault();  // Pour éviter le rafraîchissement de la page
    setAttemptedSubmit(true);

    let errorMessages = [];

    if (!firstName) {
        errorMessages.push(t('addchildrenformsubmit.firstnameerror'));
    }

    if (!dateOfBirth) {
        errorMessages.push(t('addchildrenformsubmit.birthdateerror'));
    }

    if (personalEmail) {
        if (!email && !initialValues) {
            errorMessages.push(t('addchildrenformsubmit.emailerror'));
        }
    } else {
        if (!pseudo && !initialValues) {
            errorMessages.push(t('addchildrenformsubmit.pseudoerror'));
        }
    }

    if (!autoGeneratePassword && !password && !initialValues) {
        errorMessages.push(t('addchildrenformsubmit.passworderror'));
    }

    if (!language) {
        errorMessages.push(t('addchildrenformsubmit.languageerror'));
    }

    if (!schoolSystem) {
        errorMessages.push(t('addchildrenformsubmit.scolarshiperror'));
    }

    if (errorMessages.length) {
        errorMessages.forEach(msg => enqueueSnackbar(msg, { variant: 'error' }));
        return;
    }
    
    const data = {
        email: initialValues ? initialValues.email : email,
        pseudo: initialValues ? initialValues.pseudo : pseudo,
        password: password,
        personalEmail: personalEmail,
        autoGeneratePassword: autoGeneratePassword,
        schoolSystem: schoolSystem,
        schoolClass: schoolClass,
        selectedSubjects:selectedSubjects,
        handicapSituation: handicapSituation,
        selectedHobbies: selectedHobbies,
        otherHobbies: otherHobbies,
        lastName: lastName,
        firstName: firstName,
        dateOfBirth: dateOfBirth,
        gender: gender,
        disabilityDescription: disabilityDescription,
        checkedObjectives: checkedObjectives,
        comments: comments,
        passwordError: passwordError,
        uploadedAvatar: uploadedAvatar,
        allowAvatarModification: allowAvatarModification,
        selectedAvatar: selectedAvatar,
        language: language,
        country: country
    };
    
    const resetForm = () => {
        setPersonalEmail(false);
        setEmail(false);
        setAutoGeneratePassword(true);
        setSchoolSystem('');
        setSchoolClass('');
        setSelectedSubjects([]);
        setHandicapSituation(false);
        setSelectedHobbies([]);
        setOtherHobbies("");
        setLastName("");
        setFirstName("");
        setDateOfBirth(null);
        setGender("");
        setDisabilityDescription("");
        setPseudo("");
        setCheckedObjectives([]);
        setComments("");
        setPassword("");
        setPasswordError("");
        setUploadedAvatar(null);
        setAllowAvatarModification(false);
        setSelectedAvatar(defaultAvatars[0]);
    }

    //ajout de l'utilisateur - Compte enfant
    try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
            const jwtToken = user.signInUserSession.idToken.jwtToken;
            
            let method;
            if (initialValues) {
                method = 'PUT';  // Modification
                //console.log(initialValues.email);
                //console.log(initialValues.pseudo);
            } else {
                method = 'POST';  // Ajout
            }

            const response = await API[method.toLowerCase()]('miamentor-usersinfo', '/submitchildrenform', {
                headers: {
                    'Authorization': jwtToken
                },
                body: data
            });

            if (response) {
                //console.log(response);
                enqueueSnackbar(t('addchildrenformsubmit.success'), { variant: 'success' });
                // Réinitialisation du formulaire
                onUpdateChilds();
                resetForm();
            } else {
                //console.log(response);
                enqueueSnackbar(t('addchildrenformsubmit.responseerror'), { variant: 'error' });
            }
        }
    } catch (error) {
        enqueueSnackbar(t('addchildrenformsubmit.error'), { variant: 'error' });
        console.error('Erreur lors de la soumission du formulaire:', error);
    }

    //console.log(data);
  };

  return (
      <div>
        <div>
        {/* Début formulaire : présentation section informations générales */}
        <Typography variant="h5" gutterBottom>{t('addchildrenform.commoninfo')}</Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t('addchildrenform.lastname')}
                    variant="outlined"
                    fullWidth
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t('addchildrenform.firstname')}
                    variant="filled"
                    fullWidth
                    required
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    label={t('addchildrenform.dateofbirth')}
                    type="date"
                    variant="filled"
                    fullWidth
                    required
                    value={dateOfBirth || ''}
                    onChange={e => setDateOfBirth(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="gender-label">{t('addchildrenform.gender')}</InputLabel>
                    <Select
                        labelId="gender-label"
                        id="gender"
                        label="Genre"
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                    >
                        <MenuItem value={"male"}>{t('addchildrenform.male')}</MenuItem>
                        <MenuItem value={"female"}>{t('addchildrenform.female')}</MenuItem>
                        <MenuItem value={"notSpecified"}>{t('addchildrenform.notSpecified')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControlLabel
                    control={<Checkbox onChange={() => setHandicapSituation(!handicapSituation)} />}
                    label={t('addchildrenform.disabilitycheckbox')}
                />
                <Tooltip title={t('tooltiptitle')}>
                    <InfoOutlinedIcon 
                        style={{ marginLeft: 0, color: '#666' }} 
                        onClick={() => handleIconClick(infoContents.disability)}
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={3}>
                {handicapSituation && (
                    <TextField
                        label={t('addchildrenform.disabilitytextbox')}
                        variant="outlined"
                        fullWidth
                        value={disabilityDescription}
                        onChange={e => setDisabilityDescription(e.target.value)}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="country-label">{t('addchildrenform.country')}</InputLabel>
                    <Select
                        labelId="country-label"
                        id="country"
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                    >
                        {Object.entries(countryList).map(([code, name]) => (
                            <MenuItem key={code} value={code}>{name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth required>
                    <InputLabel id="language-label">{t('addchildrenform.language')}</InputLabel>
                    <Select
                        labelId="language-label"
                        id="language"
                        value={language}
                        onChange={e => setLanguage(e.target.value)}
                    >
                        <MenuItem value="en">{t('addchildrenform.english')}</MenuItem>
                        <MenuItem value="fr">{t('addchildrenform.french')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

        <Divider style={{ margin: '20px 0' }} />

        {/* Section Compte de l'enfant */}
        <Typography variant="h5" gutterBottom >{t('addchildrenform.accounttitle')}
          <Tooltip title={t('tooltiptitle')}>
              <InfoOutlinedIcon 
                  style={{ marginLeft: 8, color: '#666' }} 
                  onClick={() => handleIconClick(infoContents.account)}
              />
          </Tooltip>
        </Typography>
        <Grid container spacing={3}>
            {!initialValues && (
                <>
                <Grid item xs={12} md={6}>
                <FormControlLabel
                    control={<Checkbox onChange={() => setPersonalEmail(!personalEmail)} />}
                    label={t('addchildrenform.personalemailcheckbox')}
                />
                {personalEmail ? (
                    <TextField 
                        label={t('addchildrenform.personalemailtextbox')} 
                        type="email" 
                        variant="outlined" 
                        fullWidth 
                        onBlur={checkEmailInCognito}
                        error={!!emailError}
                        helperText={emailError}
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                ) : (
                    <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={8} md={6}>
                        <TextField 
                            label={t('addchildrenform.pseudo')}
                            variant="outlined" 
                            fullWidth 
                            onBlur={checkGeneratedEmailInCognito}
                            error={!!emailError}
                            helperText={emailError}
                            required
                            value={pseudo}
                            onChange={e => setPseudo(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography variant="body2">@miamentor.com</Typography>
                    </Grid>
                </Grid>
                )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={<Checkbox checked={autoGeneratePassword} onChange={() => setAutoGeneratePassword(!autoGeneratePassword)} />}
                        label={t('addchildrenform.passwordgeneratorcheckbox')}
                    />
                    {!autoGeneratePassword && (
                        <TextField 
                        label={t('addchildrenform.passwordtextbox')} 
                        type="password" 
                        variant="outlined" 
                        fullWidth 
                        required
                        value={password}
                        onChange={(e) => {
                        const pwd = e.target.value;
                        setPassword(pwd);
                        if (!validatePassword(pwd)) {
                            setPasswordError("Le mot de passe ne respecte pas les prérequis");
                        } else {
                            setPasswordError("");
                        }
                        }}
                        error={!!passwordError}
                        helperText={passwordError}
                    />
                    )}
                </Grid>
                </>
            )}
            {/* Gestion avatar */}
            
            <Grid item xs={12} md={6}>
                <Typography variant="h6">{t('addchildrenform.chooseavatar')}</Typography>
                <Grid container spacing={2}>
                    {defaultAvatars.map((avatar, index) => (
                        <Grid item key={index}>
                            <Avatar 
                                src={avatar} 
                                onClick={() => setSelectedAvatar(avatar)}
                                style={{ cursor: 'pointer', border: selectedAvatar === avatar ? '2px solid blue' : 'none' }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>{t('addchildrenform.uploadavatar')}</Typography>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<ImageIcon />}
                        >
                            {t('addchildrenform.upload')}
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleAvatarUpload}
                            />
                        </Button>
                    </Grid>
                    {uploadedAvatar && (
                        <Grid item>
                            <Avatar src={uploadedAvatar} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                        control={
                            <Checkbox 
                                checked={allowAvatarModification}
                                onChange={() => setAllowAvatarModification(!allowAvatarModification)}
                            />
                        }
                        label={t('addchildrenform.allowavatarchange')}
                        style={{ display: 'block', marginTop: '15px' }}
                    />
            </Grid>
        </Grid>

        <Divider style={{ margin: '20px 0' }} />

        {/* Section Scolarité */}
        <Typography variant="h5" gutterBottom>{t('addchildrenform.scolarshiptitle')}
          <Tooltip title={t('tooltiptitle')}>
              <InfoOutlinedIcon 
                  style={{ marginLeft: 8, color: '#666' }} 
                  onClick={() => handleIconClick(infoContents.scolarship)}
              />
          </Tooltip>
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal">
                    <InputLabel required>{t('addchildrenform.scolarsystem')}</InputLabel>
                    <Select
                        value={schoolSystem}
                        onChange={e => setSchoolSystem(e.target.value)}
                    >
                        <MenuItem value="french">{t('schoolsystem.french')}</MenuItem>
                        <MenuItem value="US">{t('schoolsystem.US')}</MenuItem>
                        <MenuItem value="UK">{t('schoolsystem.UK')}</MenuItem>
                        <MenuItem value="belgium">{t('schoolsystem.belgium')}</MenuItem>
                        <MenuItem value="other">{t('schoolsystem.other')}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {schoolSystem && schoolSystem !== "other" && (
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('addchildrenform.class')}</InputLabel>
                        <Select
                            value={schoolClass}
                            onChange={e => setSchoolClass(e.target.value)}
                        >
                            {classes[schoolSystem].map(cl => (
                                <MenuItem key={cl} value={cl}>{cl}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            {schoolClass && schoolSystem !== "other" && (
                <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('addchildrenform.preferedsubjects')}</InputLabel>
                        <Select
                            multiple
                            value={selectedSubjects}
                            onChange={handleSubjectsChange}
                        >
                            {subjects[schoolClass].map(subject => (
                                <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>{t('addchildrenform.objectivestitle')}</Typography>
                {objectives.map(obj => (
                    <FormControlLabel
                    key={obj}
                        control={<Checkbox
                            name={obj}
                            checked={checkedObjectives.includes(obj)}
                            onChange={handleObjectiveChange}/>}
                        label={obj}
                    />
                ))}
            </Grid>
        </Grid>

        <Divider style={{ margin: '20px 0' }} />

        {/* Section Autres infos */}
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{t('addchildrenform.othertitle')}
                <Tooltip title={t('tooltiptitle')}>
              <InfoOutlinedIcon 
                  style={{ marginLeft: 8, color: '#666' }} 
                  onClick={() => handleIconClick(infoContents.other)}
              />
          </Tooltip>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>{t('addchildrenform.hobbiestitle')}</Typography>
            </Grid>

        {loisirsList.map(loisir => (
        <Grid item xs={12} md={6} lg={4} key={loisir}>
            <FormControlLabel
                control={
                    <Checkbox 
                        name={loisir}
                        checked={selectedHobbies.includes(loisir)}
                        onChange={handleLoisirsChange}
                    />
                }
                label={loisir}
            />
        </Grid>
    ))}

    {selectedHobbies.includes(otherTranslation) && (
        <Grid item xs={12}>
            <TextField
                label="Autre Loisir"
                value={otherHobbies}
                onChange={e => setOtherHobbies(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
        </Grid>
    )}
    {/* Commentaire */}
    <Grid item xs={12}>
        <TextField
            label={t('addchildrenform.otherinfotitle')}
            variant="outlined"
            multiline
            rows={4}
            maxLength={1000}
            fullWidth
            margin="normal"
            value={comments}
            onChange={e => setComments(e.target.value)}
        />
    </Grid>
        </Grid>

        <Button variant="contained" color="primary" style={{ marginTop: '16px' }} onClick={handleSubmit} >{t('addchildrenform.summitbutton')}</Button>
    </div>
    <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
      <div style={{ padding: '20px', width: '300px', marginTop: '50px' }}>
          <p>
              {drawerContent}
          </p>
      </div>
    </Drawer>
    </div>
  );
}

function ChildrenSettings({ onUpdateChilds }) {

    const [showForm, setShowForm] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const childrenFromSession = JSON.parse(sessionStorage.getItem('childs')) || [];
    const userFromSession = JSON.parse(sessionStorage.getItem('user')) || [];
    const [currentChild, setCurrentChild] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [childToReset, setChildToReset] = useState(null);
    
    const filteredChildren = childrenFromSession.filter(child => {
        return child.tutor ? !child.tutor.includes(userFromSession.email) : true;
      });

    const sortedChildren = [...filteredChildren].sort((a, b) => {
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        return 0;
      });

    const openResetDialog = (childEmail) => {
        setChildToReset(childEmail);
        setOpenConfirmDialog(true);
    };

    const handleConfirmReset = () => {
        if (childToReset) {
            handlePasswordReset(childToReset);
        }
        setOpenConfirmDialog(false);
    };

    const handleCloseResetDialog = () => {
        setOpenConfirmDialog(false);
        setChildToReset(null);
    };

  const handlePasswordReset = async (child) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                const jwtToken = user.signInUserSession.idToken.jwtToken;
                await API.get('miamentor-usersinfo', `/resetpassword?email=${child.email}&language=${child.language}`, {
                    headers: {
                        'Authorization': jwtToken
                    },
                });
            }
            
            setSnackbarMessage(t('childpasswordmgm.changesuccess'));
            setOpenSnackbar(true);
        } catch (error) {
            console.error(t('childpasswordmgm.changefailed'), error);
            setSnackbarMessage(t('childpasswordmgm.changefailed'));
            setOpenSnackbar(true);
        }
    };

const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnackbar(false);
};

  return (
    <div>
    {!showForm && !showBackButton && (
        <Button 
            variant="contained" 
            startIcon={<AddCircleIcon />}
            onClick={() => {
                setShowForm(true);
                setShowBackButton(true);
            }}>
            {t('addchildrenform.entrybutton')}
        </Button>
    )}

    {showBackButton && (
        <Button 
            variant="contained" 
            startIcon={<ArrowBackIcon />}
            onClick={() => {
                setShowForm(false);
                setShowBackButton(false);
                setCurrentChild(null);
            }}>
            {t('addchildrenform.backbutton')}
        </Button>
    )}

    {showForm && (
        <div>
            <ChildForm initialValues={currentChild} onUpdateChilds={onUpdateChilds}/>
        </div>
    )}

    {!showForm && (
        <Grid container spacing={3} style={{ marginTop: '16px' }}>
            {sortedChildren.map(child => (
                <Grid item xs={12} sm={6} md={4} key={child.email}>
                    <Card>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', minHeight: '200px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <Avatar src={child.uploadedAvatar ? child.uploadedAvatar : child.selectedAvatar} />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="h6">{child.firstName}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <Box display="flex" justifyContent="flex-end" spacing={1}>
                                        <IconButton onClick={() => {
                                                setCurrentChild(child);
                                                setShowForm(true);
                                                setShowBackButton(true);
                                            }}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => openResetDialog(child)}>
                                            <LockOpenIcon color="secondary" />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Typography variant="subtitle1" color="textSecondary">{child.email}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">{t(`schoolsystem.${child.schoolSystem}`)}</Typography>
                                    <Typography variant="subtitle2">{child.schoolClass}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )}
    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
        </Alert>
    </Snackbar>
    <Dialog
    open={openConfirmDialog}
    onClose={handleCloseResetDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">{t('childpasswordmgm.title')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('childpasswordmgm.confirm')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseResetDialog} color="primary">
            {t('childpasswordmgm.cancelbutton')}
        </Button>
        <Button onClick={handleConfirmReset} color="primary" autoFocus>
            {t('childpasswordmgm.confirmbutton')}
        </Button>
    </DialogActions>
</Dialog>
</div>
  );
}

export default ChildrenSettings;