import React from 'react';
import { useTranslation } from 'react-i18next';

function HomePage() {

  const { t } = useTranslation();
  
  const styles = {
    container: {
      margin: '0 auto',
      padding: '40px',
      maxWidth: '800px',
      backgroundColor: '#E5F4FF',
      borderRadius: '15px',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)'
    },
    header: {
      fontSize: '2.5rem',
      marginBottom: '30px',
      textAlign: 'center'
    },
    text: {
      textAlign: 'justify',
      marginBottom: '40px'
    },
    newsTitle: {
      fontSize: '2rem',
      marginBottom: '20px'
    },
    newsItem: {
      backgroundColor: '#FFFFFF',
      padding: '20px',
      borderRadius: '10px',
      marginBottom: '20px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
    },
    footer: {
      marginTop: '30px',
      textAlign: 'center'
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>{t('homepage.title')}</h1>
        <p>{t('homepage.subtitle')}</p>
      </header>

      <section style={styles.content}>
        <h2 style={styles.newsTitle}>{t('homepage.newsTitle')}</h2>
        <div style={styles.newsItem}>
          <p>{t('homepage.newsContent1')}</p>
        </div>
        <div style={styles.newsItem}>
          <p>{t('homepage.newsContent2')}</p>
        </div>
        <div style={styles.newsItem}>
          <p>{t('homepage.newsContent3')}</p>
        </div>
      </section>

      <footer style={styles.footer}>
        <p>{t('homepage.footerText')}</p>
      </footer>
    </div>
  );
}

export default HomePage;
