import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import './i18n';

//Amplify.configure(config);
Amplify.configure({// OPTIONAL - if your API requires authentication 
Auth: {
  identityPoolId: 'eu-west-1:c4fb4f62-7546-4e8a-a193-fd3752cd28f4', // REQUIRED - Amazon Cognito Identity Pool ID
  region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
  userPoolId: 'eu-west-1_kCuxB9uq5', // OPTIONAL - Amazon Cognito User Pool ID
  userPoolWebClientId: '5rd072ptr1bp3hd9713lf022p8', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
},
API: {
  endpoints: [
    {
      name: "miamentor-usersinfo",
      endpoint: "https://qhrn259ec0.execute-api.eu-west-1.amazonaws.com/dev",
    },
    {
      name: "miamentor-childsinfos",
      endpoint: "https://3fj7vrc2k8.execute-api.eu-west-1.amazonaws.com/dev",
    }

    
  ]
}
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
