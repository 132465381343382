import React, { useState, useEffect, useRef } from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, Switch,
  TextField, Button, Grid, useTheme, Box, FormControlLabel, Drawer, Tooltip } from '@mui/material';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import ColorModeContext from './ColorModeContext';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const predefinedThemes = {
  default: {
    userBubble: '#e0f7fa',
    aiBubble: '#c5e1a5',
    userText: '#000000',
    aiText: '#000000',
  },
  daltonien: {
    userBubble: '#FFFF00', // Jaune
    aiBubble: '#0000FF', // Bleu
    userText: '#000000', // Noir
    aiText: '#FFFFFF', // Blanc
  },
  contraste: {
    userBubble: '#000000', // Noir
    aiBubble: '#FFFFFF', // Blanc
    userText: '#FFFFFF', // Blanc
    aiText: '#000000', // Noir
  },
};

function ChildSettings() {
  const user = JSON.parse(sessionStorage.getItem('user')) || {};
  const [theme, setTheme] = useState(user.theme || 'default');
  const [darkMode, setDarkMode] = useState(user.darkMode || false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const initialColors = user.customColors ? user.customColors : predefinedThemes[theme];
  const [customColors, setCustomColors] = useState(initialColors);

  const { toggleColorMode } = React.useContext(ColorModeContext);
  const themeBw = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  const handleIconClick = (content) => {
    setDrawerContent(content);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  const infoContents = {
    "settings": (
        <div>
            <h4>{t('childsettingshelp.maintitle')}</h4>
            <p>{t('childsettingshelp.p1')}</p>
            <p>{t('childsettingshelp.p2')}</p>
            <h5>{t('childsettingshelp.subt1')}</h5>
            <p>{t('childsettingshelp.p3')}</p>
            <p>{t('childsettingshelp.p4')}</p>
            <h5>{t('childsettingshelp.subt2')}</h5>
            <p>{t('childsettingshelp.p5')}</p>
            <p>{t('childsettingshelp.p6')}</p>
            <h5>{t('childsettingshelp.subt3')}</h5>
            <p>{t('childsettingshelp.p7')}</p>
            <p>{t('childsettingshelp.p8')}</p>
        </div>
    )
};


    useEffect(() => {
      setDarkMode(themeBw.palette.mode);
    }, [themeBw.palette.mode]);
    
    const firstRender = useRef(true);

    useEffect(() => {
      if (theme in predefinedThemes) {
        setCustomColors(predefinedThemes[theme]);
    }
    }, [theme]);

  const handleColorChange = (color, value) => {
    setCustomColors({ ...customColors, [color]: value });
    if (theme !== 'custom') {
      setTheme('custom');

    }
  };

  const handleSave = async () => {
    
    // Mise à jour des propriétés de l'objet user
    user.theme = theme;
    user.darkMode = themeBw.palette.mode;
    user.customColors = customColors;
    sessionStorage.setItem('user', JSON.stringify(user));

    // Mise à jour des préférences dans la base de données
    const userAuthenticated = await Auth.currentAuthenticatedUser();
      if (userAuthenticated) {
        //console.log(dataToSend);
        const jwtToken = userAuthenticated.signInUserSession.idToken.jwtToken;
        

        try {
          const response = await API.post('miamentor-usersinfo', '/setchildsettings', {
            body: {
              theme,
              darkMode,
              customColors,
            },
            headers: {
              'Authorization': jwtToken
          }
          });
          enqueueSnackbar(t('childsettings.updatesuccess'), { variant: 'error' });
          //console.log(response);
        } catch (error) {
          console.error('Error updating preferences', error);
          enqueueSnackbar(t('childsettings.updateerror'), { variant: 'error' });
        }
      }
    };

  return (
    <div>
      <Typography variant="h4" mb={3}>
        {t('childsettings.parameterstitle')}
        <Tooltip title={t('tooltiptitle')}>
            <InfoOutlinedIcon
              style={{ marginLeft: 8, color: '#666' }}
              onClick={() => handleIconClick(infoContents.settings)} />
          </Tooltip>
      </Typography>

      <Grid container spacing={3}>
        {/* Thème et Mode Sombre */}
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>{t('childsettings.themetitle')}</InputLabel>
            <Select value={theme} onChange={(e) => setTheme(e.target.value)} label="Thème">
              <MenuItem value="default">{t('childsettings.themedefault')}</MenuItem>
              <MenuItem value="daltonien">{t('childsettings.themedaltonien')}</MenuItem>
              <MenuItem value="contraste">{t('childsettings.themecontraste')}</MenuItem>
              <MenuItem value="custom">{t('childsettings.themecustom')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
        <Box marginTop={2}>
          <FormControlLabel 
              control={
                <Switch 
                checked={themeBw.palette.mode === 'dark'} 
                onChange={toggleColorMode} 
                color="primary"
                margin="normal"
              />
              }
              label={t('accountsettings.darkMode')}
          />
          </Box>
        </Grid>

        <>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('childsettings.userbubble')}
              type="color"
              value={customColors.userBubble}
              onChange={(e) => handleColorChange('userBubble', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('childsettings.aibubble')}
              type="color"
              value={customColors.aiBubble}
              onChange={(e) => handleColorChange('aiBubble', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('childsettings.usertext')}
              type="color"
              value={customColors.userText}
              onChange={(e) => handleColorChange('userText', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('childsettings.aitext')}
              type="color"
              value={customColors.aiText}
              onChange={(e) => handleColorChange('aiText', e.target.value)}
            />
          </Grid>
        </>
      </Grid>

      <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: '16px' }}>
      {t('childsettings.savebutton')}
      </Button>
      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <div style={{ padding: '20px', width: '300px', marginTop: '50px' }}>
            {drawerContent}
        </div>
      </Drawer>
    </div>
  );
}

export default ChildSettings;
