import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './hidesignup.css'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, ListItemAvatar, Avatar  } from '@mui/material';
import { AddCircleRounded, ExitToApp } from '@mui/icons-material';
import PortraitIcon from '@mui/icons-material/Portrait';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import RoleIcon from '@mui/icons-material/VerifiedUser';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListItemButton from '@mui/material/ListItemButton';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import HomePage from './Home';
import ChildrenSettings from './Parents/ChildrenSettings';
import Account from './Common/Account';
import Settings from './Common/Settings';
import ParentDashboard from './Parent-Dashboard';
import MiaMentor from './MiaMentor';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createTheme } from '@mui/material';
import ColorModeContext from './ColorModeContext';
import i18n from './i18n';
import ProtectedRoute from './ProtectedRoute';
import ChildAccount from './ChildAccount';
import ChildSettings from './ChildSettings';
import ChildFollow from './Parents/ChildFollow';


const drawerWidth = 200;

function TopBar({ signOut, setDrawerOpen}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = JSON.parse(sessionStorage.getItem('user'));
  const { t } = useTranslation();
  const uploadedAvatar = user ? user.uploadedAvatar : null;
  const selectedAvatar = user ? user.selectedAvatar : null;
  
    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const history = useNavigate();
    
    const navigateToAccount = () => {
        handleMenuClose();
        if(user.role === 'child'){
          history('/childaccount');
        } else {
          history('/account');
        }
    }

    const navigateToSettings = () => {
        handleMenuClose();
        if(user.role === 'child'){
          history('/childsettings');
        } else {
          history('/settings');
        }
    }

    const handleLogout = async () => {
      // Effacer le cache
      sessionStorage.clear();
      localStorage.clear();
      
      // Effectuer la déconnexion avec Cognito
      await Auth.signOut();
    };

    return (
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
        <Link to="/">
            <IconButton edge="start" color="inherit" aria-label="menu">
              <img src={process.env.PUBLIC_URL + '/miamentor-logo.png'} alt="Mon icône" style={{ width: '50px', height: '50px' }} />
            </IconButton>
          </Link>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Mia Mentor
          </Link>
          </Typography>
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <img
              src={uploadedAvatar ? uploadedAvatar : selectedAvatar ? selectedAvatar : '/avatars/6.png'}
              alt="User Avatar"
              style={{ width: '50px', marginRight: '8px' }}
            />
            <span style={{ marginLeft: '8px' }}>{user?.firstName || ''}</span>
            <span style={{ marginLeft: '8px' }}>{user?.lastName || ''}</span>
            {/*<span style={{ marginLeft: '8px' }}>{user?.role || ''}</span>*/}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={navigateToAccount}><PortraitIcon /> {t('profile')}</MenuItem>
            <MenuItem onClick={navigateToSettings}><ManageAccountsIcon /> {t('settings')}</MenuItem>
            <MenuItem onClick={handleLogout}><ExitToApp /> {t('logout')}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    )
}

function Sidebar(props) {
  const { user, childs } = props;
        const location = useLocation();

    const sortedChildren = childs && [...childs].sort((a, b) => {
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      return 0;
    });

  return (
      
      <Drawer 
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
      >
        <Toolbar />
        {user && user.role === "admin" && (
          <Box sx={{ overflow: 'auto' }}>
          <List>
              <ListItem>
                  <ListItemText primary="Institut" />
              </ListItem>
              <Divider />
              {[['Curriculum', SchoolIcon], ['Périodes', SchoolIcon], ['Courses', SchoolIcon], ['Evaluations', SchoolIcon]].map(([text, Icon], index) => (
                  <ListItem key={text}>
                      <ListItemIcon><Icon /></ListItemIcon>
                      <ListItemText primary={text} />
                  </ListItem>
              ))}
              <ListItem>
                  <ListItemText primary="Administration" />
              </ListItem>
              <Divider />
              {[['Utilisateurs', GroupIcon], ['Rôles', RoleIcon]].map(([text, Icon], index) => (
                  <ListItem key={text}>
                      <ListItemIcon><Icon /></ListItemIcon>
                      <ListItemText primary={text} />
                  </ListItem>
              ))}
          </List>
          </Box>
        )}
        {user && user.role === "parent" && (
          <Box sx={{ overflow: 'auto' }}>
          <List>
              <ListItem>
                  <ListItemText primary={t('chlidrentitlemenu')} />
              </ListItem>
              <Divider />
              {(!childs || childs.length === 0) ? (
                <>
                    <ListItem>
                        <ListItemText primary={t('chlidrenemptymenu')} 
                        align="center"
                        style={{ color: '#888' }}
                        />
                    </ListItem>
                    <ListItem style={{ justifyContent: 'center' }}>
                        <ListItemIcon><ArrowDownwardIcon /></ListItemIcon>
                    </ListItem>
                </>
              ) : (
                <>
                    {
                        sortedChildren.map((child, index) => (
                            <ListItemButton 
                                key={index} 
                                component={Link} 
                                to={`/childfollow/${child.email}`}
                                sx={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                }}
                                selected={location.pathname === `/childfollow/${child.email}`}
                            >
                                <ListItemAvatar>
                                    <Avatar src={child.uploadedAvatar || child.selectedAvatar} />
                                </ListItemAvatar>
                                <ListItemText primary={child.firstName} />
                            </ListItemButton>
                        ))
                    }
                </>
              )}
              <ListItem>
                  <ListItemText primary={t('familytitlemenu')} />
              </ListItem>
              <Divider />
              {[
                  ['chlidrentitlesetting', GroupIcon, '/childrensettings'], 
                  ['settingstitle', SettingsIcon, '/settings'],
                  ['account', ManageAccountsIcon, '/account']
              ].map(([text, Icon, path], index) => (
                  <ListItemButton 
                      key={t(text)} 
                      component={Link} 
                      to={path}
                      sx={{
                          textDecoration: 'none',
                          color: 'inherit'
                      }}
                      selected={location.pathname === path}  // Met en évidence si c'est l'élément actif
                  >
                      <ListItemIcon><Icon /></ListItemIcon>
                      <ListItemText primary={t(text)} />
                  </ListItemButton>
              ))}
          </List>
          </Box>
        )}
        {user && user.role === "student" && (
          <Box sx={{ overflow: 'auto' }}>
          <List>
              <ListItem>
                  <ListItemText primary="Institut" />
              </ListItem>
              <Divider />
              {[['Curriculum', SchoolIcon], ['Périodes', SchoolIcon], ['Courses', SchoolIcon], ['Evaluations', SchoolIcon]].map(([text, Icon], index) => (
                  <ListItem key={text}>
                      <ListItemIcon><Icon /></ListItemIcon>
                      <ListItemText primary={text} />
                  </ListItem>
              ))}
              <ListItem>
                  <ListItemText primary="Administration" />
              </ListItem>
              <Divider />
              {[['Utilisateurs', GroupIcon], ['Rôles', RoleIcon]].map(([text, Icon], index) => (
                  <ListItem key={text}>
                      <ListItemIcon><Icon /></ListItemIcon>
                      <ListItemText primary={text} />
                  </ListItem>
              ))}
          </List>
          </Box>
        )}
      </Drawer>
  );
}

function App({ signOut, user, setDrawerOpen }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [childs, setChilds] = useState([]);
  const [loadingChilds, setLoadingChilds] = useState(true);
  const [groups, setGroups] = useState(null);

  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  )
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );


  async function fetchChilds() {
    try {
      setLoadingChilds(true);

      const user = await Auth.currentAuthenticatedUser();
      const jwtToken = user.signInUserSession.idToken.jwtToken;

      const fetchedChilds = await API.get('miamentor-childsinfos', '/getchildren', {
        headers: {
          'Authorization': jwtToken,
        },
      });

      if (fetchedChilds) {
        sessionStorage.setItem('childs', JSON.stringify(fetchedChilds));
        setChilds(fetchedChilds);
        console.log("R2cupération des enfants : " + fetchedChilds);
        //console.log("Liste des enfants: ", sessionStorage.childs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingChilds(false);
    }
  }

  const fetchUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      Auth.currentAuthenticatedUser()
      .then(user => {
        const userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
        setGroups(userGroups);
      })
      .catch(err => console.log(err));
      if (user) {
        // L'utilisateur est connecté, exécutez votre code ici
        
        const jwtToken = user.signInUserSession.idToken.jwtToken;

        const data = await API.get('miamentor-usersinfo', '/getuserdetails', {
            headers: {
                'Authorization': jwtToken
            }
        });
        
        if (data && data.role) {
            sessionStorage.setItem('user', JSON.stringify(data));
            setCurrentUser(data);
            console.log("Session storage : ");
            console.log(sessionStorage);
            const userStored = JSON.parse(sessionStorage.getItem('user'));
            if(userStored.darkMode){
              setMode(userStored.darkMode);
            }

            if(userStored.language){
              console.log(userStored.language);
              i18n.changeLanguage(userStored.language);
            }
            if(data.role === "parent"){
              fetchChilds();
            }
        }
      }

    } catch (error) {
      console.log("L'utilisateur n'est pas connecté");
    }
  }
  useEffect(() => {
    fetchUserData();

    }, []);

    function HomePageDefault() {
      const navigate = useNavigate();
      const userGroups = user.signInUserSession.idToken.payload['cognito:groups'];
      
      useEffect(() => {
        if (userGroups) {
          console.log(userGroups);
          if (userGroups.includes("Admin")) {
            navigate('/admin-dashboard');
          } else if (userGroups.includes("Childs")) {
            //navigate('/parent-dashboard');
            navigate('/miamentor');
          }
        }
      }, [userGroups, navigate]);
        
      return <HomePage />;
    }

    

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
    <Router>
      <SnackbarProvider maxSnack={5}>
      <Box sx={{ display: 'flex' }}>
      
        <CssBaseline />
        <TopBar signOut={signOut} user={user} setDrawerOpen={setDrawerOpen} />
        {!loadingChilds && currentUser && groups !== "Childs" && <Sidebar user={currentUser} childs={childs} />}
        <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: '1000px', margin: '0 auto' }}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<HomePageDefault />} />
            <Route path="/childrensettings" element={
              <ProtectedRoute groups={groups} allowedGroups={["Parents"]}>
                <ChildrenSettings onUpdateChilds={fetchChilds}/>
              </ProtectedRoute>
            }/>
            <Route path="/parent-dashboard" element={
              <ProtectedRoute groups={groups} allowedGroups={["Parents"]}>
                <ParentDashboard />
              </ProtectedRoute>
            }/>
            <Route path="/account" element={
              <ProtectedRoute groups={groups} allowedGroups={["Parents", "Admin"]}>
                <Account fetchUserData={fetchUserData}/>
              </ProtectedRoute>
            }/>
            <Route path="/settings" element={
              <ProtectedRoute groups={groups} allowedGroups={["Parents"]}>
                <Settings onUpdateChilds={fetchChilds}/>
              </ProtectedRoute>
            }/>
            <Route path="/miamentor" element={
              <ProtectedRoute groups={groups} allowedGroups={["Childs"]}>
                <MiaMentor/>
              </ProtectedRoute>
            }/>
            <Route path="/childaccount" element={
              <ProtectedRoute groups={groups} allowedGroups={["Childs"]}>
                <ChildAccount/>
              </ProtectedRoute>
            }/>
            <Route path="/ChildSettings" element={
              <ProtectedRoute groups={groups} allowedGroups={["Childs"]}>
                <ChildSettings/>
              </ProtectedRoute>
            }/>
            <Route path="/ChildFollow/:email" element={
              <ProtectedRoute groups={groups} allowedGroups={["Parents"]}>
                <ChildFollow/>
              </ProtectedRoute>
            }/>
          </Routes>

          
        </Box>
      </Box>
      </SnackbarProvider>
    </Router>
    </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default withAuthenticator(App);
