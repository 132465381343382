import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  Grid,
  Typography,
  Avatar,
  Card,
  CardContent,
  Badge,
  Tooltip,
  IconButton ,
  Drawer,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


function ChildAccount() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')) || {});
  const [uploadedAvatar, setUploadedAvatar] = useState(user.uploadedAvatar || null);
  const [unlockedAvatars, setUnlockedAvatars] = useState([]);
  const handleAvatarUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setUploadedAvatar(reader.result);
        };
        reader.readAsDataURL(file);
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  const handleIconClick = (content) => {
    setDrawerContent(content);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  const infoContents = {
    "account": (
        <div>
            <h4>{t('childaccounthelp.maintitle')}</h4>
            <p>{t('childaccounthelp.p1')}</p>
            <p>{t('childaccounthelp.p2')}</p>
            <h5>{t('childaccounthelp.subt1')}</h5>
            <p>{t('childaccounthelp.p3')}</p>
            <p>{t('childaccounthelp.p4')}</p>
            <h5>{t('childaccounthelp.subt2')}</h5>
            <p>{t('childaccounthelp.p5')}</p>
            <p>{t('childaccounthelp.p6')}</p>
            <h5>{t('childaccounthelp.subt3')}</h5>
            <p>{t('childaccounthelp.p7')}</p>
            <p>{t('childaccounthelp.p8')}</p>
        </div>
    )
};

const defaultAvatars = [
  '/avatars/1.png',
  '/avatars/2.png',
  '/avatars/3.png',
  '/avatars/4.png',
  '/avatars/5.png',
  '/avatars/6.png',
  '/avatars/7.png',
  '/avatars/8.png',
  '/avatars/9.png',
  // ... autres URLs
  ];
  const [selectedAvatar, setSelectedAvatar] = useState();

  const preniumGirlAvatars = [
    '/avatars/pf1.png',
    '/avatars/pf2.png',
    '/avatars/pf3.png',
    '/avatars/pf4.png',
    '/avatars/pf5.png',
    '/avatars/pf6.png',
    ];

  const preniumBoyAvatars = [
    '/avatars/pm1.png',
    '/avatars/pm2.png',
    '/avatars/pm3.png',
    '/avatars/pm4.png',
    '/avatars/pm5.png',
    '/avatars/pm6.png',
    ];

    const unlockRules = {
      '/avatars/pf1.png': t('childaccount.chat10'),
      '/avatars/pf2.png': t('childaccount.chat100'),
      '/avatars/pf3.png': t('childaccount.chat500'),
      '/avatars/pf4.png': t('childaccount.days3'),
      '/avatars/pf5.png': t('childaccount.days5'),
      '/avatars/pf6.png': t('childaccount.days10'),
      '/avatars/pm1.png': t('childaccount.chat10'),
      '/avatars/pm2.png': t('childaccount.chat100'),
      '/avatars/pm3.png': t('childaccount.chat500'),
      '/avatars/pm4.png': t('childaccount.days3'),
      '/avatars/pm5.png': t('childaccount.days5'),
      '/avatars/pm6.png': t('childaccount.days10'),
      
      // ... autres règles pour chaque avatar
    };
  // Charger les avatars et les succès débloqués depuis l'API
  useEffect(() => {
    // Récupérer l'utilisateur stocké dans sessionStorage
    const user = JSON.parse(sessionStorage.getItem('user'));
  
    // Vérifier si user et user.unlockedAvatars existent
    if (user && user.unlockedAvatars) {
      // Transformer l'objet user.unlockedAvatars en un tableau de clés (chemins des avatars)
      const avatarsArray = Object.keys(user.unlockedAvatars);
      
      // Mettre à jour l'état unlockedAvatars
      setUnlockedAvatars(avatarsArray);
    }
  }, []);


  const saveAvatar = async () => {
    if (selectedAvatar || uploadedAvatar) {
      const userAuthenticated = await Auth.currentAuthenticatedUser();
      if (userAuthenticated) {
        //console.log(dataToSend);
        const jwtToken = userAuthenticated.signInUserSession.idToken.jwtToken;
        

        try {
          const response = await API.post('miamentor-usersinfo', '/setchildaccount', {
            body: {
              selectedAvatar,
              uploadedAvatar,
            },
            headers: {
              'Authorization': jwtToken
          }
          });
          enqueueSnackbar(t('childaccount.savesuccess'), { variant: 'success' });
          console.log(t('childaccount.savesuccess'));
          //console.log(response);
        } catch (error) {
          console.error('Error updating account', error);
          enqueueSnackbar(t('childaccount.saveerror'), { variant: 'error' });
        }
      }
    } else {
      enqueueSnackbar(t('childaccount.saveerror'), { variant: 'error' });
      console.warn(t('childaccount.saveerror'));
    }
  };
  
  return (
    <><Box sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" mb={4}>
          {t('childaccount.accountitle')}
          <Tooltip title={t('tooltiptitle')}>
            <InfoOutlinedIcon
              style={{ marginLeft: 8, color: '#666' }}
              onClick={() => handleIconClick(infoContents.account)} />
          </Tooltip>
        </Typography>
        <Button variant="contained" onClick={saveAvatar}>{t('childaccount.savebutton')}</Button>
      </Box>

      {/* Section d'avatar */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>{t('childaccount.avatartitle')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mb: 2 }}>
            <Avatar src={user.uploadedAvatar || user.selectedAvatar} sx={{ width: 72, height: 72 }} />
            {user.allowAvatarModification && (
              <Button
                variant="contained"
                component="label"
                startIcon={<ImageIcon />}
              >
                {t('childaccount.downloadbutton')}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleAvatarUpload} />
              </Button>
            )}
            {uploadedAvatar && (
              <Grid item>
                <Avatar src={uploadedAvatar} />
              </Grid>
            )}
          </Box>

          {/* Avatar par défaut */}
          <Typography variant="subtitle1" gutterBottom>{t('childaccount.defaultavatartitle')}</Typography>
          <Grid container spacing={2} mb={3}>
            {defaultAvatars.map((avatar, index) => (
              <Grid item key={index}>
                <Avatar
                  src={avatar}
                  onClick={() => setSelectedAvatar(avatar)}
                  style={{ cursor: 'pointer', border: selectedAvatar === avatar ? '2px solid blue' : 'none' }} />
              </Grid>
            ))}
          </Grid>

          {/* Avatars à débloquer */}
          <Typography variant="subtitle1" gutterBottom>{t('childaccount.lockedavatartitle')}</Typography>
          <Grid container spacing={2}>
            {preniumGirlAvatars.map((avatar, index) => (
              <Grid item key={index} position="relative">
                <Tooltip title={unlockRules[avatar]} arrow>
                  <div>
                    <Avatar
                      src={avatar}
                      style={{
                        cursor: unlockedAvatars.includes(avatar) ? 'pointer' : 'not-allowed',
                        opacity: unlockedAvatars.includes(avatar) ? 1 : 0.5,
                        position: 'relative',
                        border: selectedAvatar === avatar ? '2px solid blue' : 'none'
                      }}
                      onClick={() => {
                        if (unlockedAvatars.includes(avatar)) {
                          setSelectedAvatar(avatar);
                        }
                      } } />
                    {!unlockedAvatars.includes(avatar) && (
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          cursor: 'not-allowed',
                        }}
                        disabled
                      >
                        <LockIcon />
                      </IconButton>
                    )}
                  </div>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '10px' }}>
            {preniumBoyAvatars.map((avatar, index) => (
              <Grid item key={index} position="relative">
                <Tooltip title={unlockRules[avatar]} arrow>
                  <div>
                    <Avatar
                      src={avatar}
                      style={{
                        cursor: unlockedAvatars.includes(avatar) ? 'pointer' : 'not-allowed',
                        opacity: unlockedAvatars.includes(avatar) ? 1 : 0.5,
                        position: 'relative',
                        border: selectedAvatar === avatar ? '2px solid blue' : 'none'
                      }}
                      onClick={() => {
                        if (unlockedAvatars.includes(avatar)) {
                          setSelectedAvatar(avatar);
                        }
                      } } />
                    {!unlockedAvatars.includes(avatar) && (
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          cursor: 'not-allowed',
                        }}
                        disabled
                      >
                        <LockIcon />
                      </IconButton>
                    )}
                  </div>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
    <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <div style={{ padding: '20px', width: '300px', marginTop: '50px' }}>
            {drawerContent}
        </div>
      </Drawer></>
  );
}

export default ChildAccount;
