import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Drawer,
} from '@mui/material';


function ParentDashboard() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };


  useEffect(() => {
    enqueueSnackbar("t('addchildrenformsubmit.error')", { variant: 'error' });
  }, []);
  
  return (
    <><div>
      Voici la page d'accueil avec un texte un peu long pour voir comment ça s'affiche sur la page.
      {/* Votre contenu de HomePage ici */}
    </div><Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <div style={{ padding: '20px', width: '300px', marginTop: '50px' }}>
          {drawerContent}
        </div>
      </Drawer></>
  );
}

export default ParentDashboard;