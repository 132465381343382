import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, groups, allowedGroups }) {
  const isAuthenticated = !!groups;
  const isAuthorized = groups ? allowedGroups.some(group => groups.includes(group)) : false;

  if (!isAuthenticated || !isAuthorized) {
    return <Navigate to="/" />;
  }

  return children;
}

export default ProtectedRoute;
