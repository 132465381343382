import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Divider, Dialog, DialogContent, DialogTitle, DialogActions,
     TextField, Button, Select, MenuItem, Snackbar, Alert, Tooltip, Drawer, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';


function Assignations({ onUpdateChilds }) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogType, setDialogType] = useState(""); // "parent1", "parent2", "tutor", or "license"
    const [selectedChild, setSelectedChild] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [deleteType, setDeleteType] = useState(""); // "parent1", "parent2", "tutor", or "license"
    const [confirmUnassignDialog, setConfirmUnassignDialog] = useState(false);
    const [allLicenses, setAllLicenses] = useState([]);
    const [childLicenses, setChildLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState("");
    const [dialogContext, setDialogContext] = useState({ type: null, rowData: null });
    const [email, setEmail] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState(null);
    
    const childrenFromSession = JSON.parse(sessionStorage.getItem('childs')) || [];
    const userFromSession = JSON.parse(sessionStorage.getItem('user')) || [];

    const filteredChildren = childrenFromSession.filter(child => {
        return child.tutor ? !child.tutor.includes(userFromSession.email) : true;
      });

    const sortedChildren = [...filteredChildren].sort((a, b) => {
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        return 0;
      });

      useEffect(() => {
        async function fetchLicenses() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const jwtToken = user.signInUserSession.idToken.jwtToken;
    
                const fetchedLicenses = await API.get('miamentor-usersinfo', '/getlicenses', {
                    headers: {
                        'Authorization': jwtToken,
                    },
                });
    
                if (fetchedLicenses) {
                    
                    sessionStorage.setItem('licenses', JSON.stringify(fetchedLicenses));
                    setAllLicenses(fetchedLicenses);

                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);  

                    const oneMonthAgo = new Date(currentDate);
                    oneMonthAgo.setMonth(currentDate.getMonth() - 1); 

                    const filteredLicenses = fetchedLicenses.filter(license => {
                        const lastAssignDate = new Date(license.lastAssignDate);
                        //console.log("ID : ", license.ID);
                        //console.log("EndDate : ", license.endDate);
                        //console.log("LastAssign : ", lastAssignDate);
                        if (license.endDate === "Until Canceled" && license.lastAssignDate) {
                            return lastAssignDate <= oneMonthAgo;
                        }
                        if (license.endDate === "Until Canceled" && !license.lastAssignDate) {
                            return true;
                        }
                        const licenseEndDate = new Date(license.endDate);
                        licenseEndDate.setHours(0, 0, 0, 0);

                        if (!license.lastAssignDate){
                            console.log("Jarrive ici ?")
                            return (licenseEndDate > currentDate);
                        }
                        
                        return (licenseEndDate > currentDate) && (lastAssignDate <= oneMonthAgo);
                    });
                    //console.log("Filtered : ", filteredLicenses);
                    setChildLicenses(filteredLicenses);
                    //console.log(childLicenses);
                    //console.log("Liste des Licenses: ", sessionStorage.licenses);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchLicenses();
    }, []);


    /*useEffect(() => {
        console.log("Child Licenses Updated: ", childLicenses);
      }, [childLicenses]);
      */
      
    const sortedLicenses = [...allLicenses].sort((a, b) => {
        const currentDate = new Date().toISOString().split("T")[0];
        if (a.endDate < currentDate) return 1;
        if (b.endDate < currentDate) return -1;
        if (a.canceled && a.endDate > currentDate) return 1;
        if (b.canceled && b.endDate > currentDate) return -1;
        return 0;
    });
    const handleIconClick = (content) => {
        setDrawerContent(content);
        setDrawerOpen(true);
    };

    const infoContents = {
        "assignements": (
            <div>
                <h4>{t('assignmentshelp.maintitle')}</h4>
                <p>{t('assignmentshelp.p1')}</p>
                <p>{t('assignmentshelp.p2')}</p>
                <h5>{t('assignmentshelp.subt1')}</h5>
                <p>{t('assignmentshelp.p3')}</p>
                <p>{t('assignmentshelp.p4')}</p>
                <h5>{t('assignmentshelp.subt2')}</h5>
                <p>{t('assignmentshelp.p5')}</p>
                <p>{t('assignmentshelp.p6')}</p>
                <h5>{t('assignmentshelp.subt3')}</h5>
                <p>{t('assignmentshelp.p7')}</p>
                <p>{t('assignmentshelp.p8')}</p>
            </div>
        ),    
        "licenses": (
          <div>
                <h4>{t('licenseshelp.maintitle')}</h4>
                <p>{t('licenseshelp.p1')}</p>
                <p>{t('licenseshelp.p2')}</p>
                <h5>{t('licenseshelp.subt1')}</h5>
                <p>{t('licenseshelp.p3')}</p>
                <p>{t('licenseshelp.p4')}</p>
                <h5>{t('licenseshelp.subt2')}</h5>
                <p>{t('licenseshelp.p5')}</p>
                <p>{t('licenseshelp.p6')}</p>
                <h5>{t('licenseshelp.subt3')}</h5>
                <p>{t('licenseshelp.p7')}</p>
                <p>{t('licenseshelp.p8')}</p>
          </div>
    
        )
    };

    const handleCloseDrawer = () => {
        setDrawerOpen(false);
    };

    const handleOpenDialog = (type, child) => {
        setSelectedChild(child);
        setDialogContext({ type, child });
        if (type.startsWith('remove')) {
            setDeleteType(type);
            setConfirmUnassignDialog(true);
        } else {
            setDialogType(type);
            setOpenDialog(true);
        }
    }
    
    const handleCloseConfirmUnassign = () => {
        setDeleteType("");
        setSelectedChild(null);
        setConfirmUnassignDialog(false);
    }
    const isValidEmail = (email) => {
        // Vous pouvez utiliser une expression régulière pour une vérification plus stricte
        return email.includes('@');
    };

    const handleUnassign = async () => {
        if (!dialogContext.child) {
            console.error('No row data provided.');
            enqueueSnackbar(t('assignsettings.unknowactiontype'), { variant: 'error' });
            return;
        }
        let params = {
            type: deleteType,
            child: dialogContext.child,
            value: dialogContext.type,
        };
       
        await callAssignmentsApi(params);
    
        setDialogContext({ type: null, rowData: null });
        handleCloseConfirmUnassign();
    }

    const handleAssign = async () => {
        if (!dialogType) {
            console.error("Aucun type d'action défini.");
            enqueueSnackbar(t('assignsettings.unknowactiontype'), { variant: 'error' });
            return;
        }
    
        let params = {
            type: dialogType,
            child: dialogContext.child,
        };

        switch (dialogType) {
            case 'addParent1':
                if (!isValidEmail(email)) {
                    console.error("L'e-mail fourni n'est pas valide.");
                    enqueueSnackbar(t('assignsettings.emailnotvalid'), { variant: 'error' });
                    return;
                }
                params.value = email;
                await callAssignmentsApi(params);
                break;
                
            case 'addParent2':
                if (!isValidEmail(email)) {
                    console.error("L'e-mail fourni n'est pas valide.");
                    enqueueSnackbar(t('assignsettings.emailnotvalid'), { variant: 'error' });
                    return;
                }
                params.value = email;
                await callAssignmentsApi(params);
                break;
                
            case 'addTutor':
                if (!isValidEmail(email)) {
                    console.error("L'e-mail fourni n'est pas valide.");
                    enqueueSnackbar(t('assignsettings.emailnotvalid'), { variant: 'error' });
                    return;
                }
                params.value = email;
                await callAssignmentsApi(params);
                break;

            case 'addLicense':
                if (!selectedLicense) {
                    console.error("Aucune licence sélectionnée.");
                    enqueueSnackbar(t('assignsettings.nolicenseselected'), { variant: 'error' });
                    return;
                }
                params.value = selectedLicense;
                await callAssignmentsApi(params);
                break;

            default:
                console.error(`Type d'action inconnu: ${dialogType}`);
                enqueueSnackbar(t('assignsettings.unknowactiontype'), { variant: 'error' });
                return;
        
            }

        setEmail("");
        setSelectedLicense("");
        setDialogType("");
        setDialogContext({ type: null, rowData: null });
        setOpenDialog(false);
    };

    async function callAssignmentsApi(params) {
        const { type, child, value } = params;

        try {
            const user = await Auth.currentAuthenticatedUser();
            const jwtToken = user.signInUserSession.idToken.jwtToken;
    
            const result = await API.post('miamentor-usersinfo', '/assignments', {
                headers: {
                    'Authorization': jwtToken,
                },
                body: {
                    type: type,
                    child: child,
                    value: value
                }
            });
            console.log(params);
            // Traitez le résultat si nécessaire
            onUpdateChilds();
            console.log("Résultat de la désaffectation:", result);
    
        } catch (error) {
            console.log("Erreur lors de la désaffectation:", error);
        }
    };
    
    const handleCloseDialog = () => {
        setDialogType("");
        setSelectedChild(null);
        setOpenDialog(false);
    }

    const handleOpenSnackbar = (type) => {
        setDeleteType(type);
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = () => {
        setDeleteType("");
        setOpenSnackbar(false);
    }

    const handleValidateDeletion = () => {
        // Ici, implémentez la logique de suppression.
        handleCloseSnackbar();
    }

    const columnsChildren = [
        { 
          field: 'firstName', 
          headerName: t('assignsettings.childName'), 
          width: 100,
          renderCell: (params) => (
                <div title={params.row.email}>
                    {params.value}
                </div>
            )
        },
        {
          field: 'parent1',
          headerName: t('assignsettings.parent1'),
          width: 200,
          renderCell: (params) => (
            <div>
              {params.row.parent1 || ""}
              <IconButton 
                size="small" 
                onClick={() => handleOpenDialog(params.row.parent1 ? 'removeParent1' : 'addParent1', params.row)}
              >
                {params.row.parent1 ? <CloseIcon /> : <AddIcon />}
              </IconButton>
            </div>
          )
        },
        {
          field: 'parent2',
          headerName: t('assignsettings.parent2'),
          width: 200,
          renderCell: (params) => (
            <div>
              {params.row.parent2 || ""}
              <IconButton 
                size="small" 
                onClick={() => handleOpenDialog(params.row.parent2 ? 'removeParent2' : 'addParent2', params.row)}
              >
                {params.row.parent2 ? <CloseIcon /> : <AddIcon />}
              </IconButton>
            </div>
          )
        },
        {
          field: 'tutor',
          headerName: t('assignsettings.tutor'),
          width: 200,
          renderCell: (params) => (
            <div>
              {params.value || ""}
              <IconButton 
                size="small" 
                onClick={() => handleOpenDialog(params.value ? 'removeTutor' : 'addTutor', params.row)}
              >
                {params.value ? <CloseIcon /> : <AddIcon />}
              </IconButton>
            </div>
          )
        },
        {
            field: 'license',
            headerName: t('assignsettings.license'),
            width: 130,
            renderCell: (params) => (
              <div>
                {params.value ? (
                  childLicenses.some(license => license.ID === params.value) ? (
                    <>
                      {params.value}
                      <IconButton 
                        size="small" 
                        onClick={() => handleOpenDialog('removeLicense', params.row)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    params.value
                  )
                ) : (
                  <IconButton 
                    size="small" 
                    onClick={() => handleOpenDialog('addLicense', params.row)}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>
            )
          }
    ];
    const columnsLicenses = [
        { field: 'ID', headerName: t('assignsettings.licenseNo'), width: 130 },
        { field: 'type', headerName: t('assignsettings.type'), width: 100 },
        { field: 'periodicity', headerName: t('assignsettings.periodicity'), width: 130 },
        { field: 'startDate', headerName: t('assignsettings.startDate'), width: 150 },
        { field: 'endDate', headerName: t('assignsettings.endDate'), width: 150 },
        { field: 'assignation', headerName: t('assignsettings.assignation'), width: 150 },
        { field: 'lastAssignDate', headerName: t('assignsettings.lastassigndate'), width: 150 }
    ];

    const getRowClassName = (params) => {
        const currentDate = new Date().toISOString().split("T")[0]; // Date actuelle sous le format yyyy-mm-dd
        if (params.row.endDate < currentDate) {
            return "license-expired";
        }
        if (params.row.canceled && params.row.endDate >= currentDate) {
            return "license-warning";
        }
        return "license-valid";
    };
    
    return (
        <div>
            <style>
            {`
                .license-expired { background-color: #FFC1C1; }
                .license-warning { background-color: #FFE2B8; }
                // .license-valid n'a pas besoin de styles spécifiques
            `}
        </style>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h4">{t('assignsettings.childAssignments')}</Typography>
                        <Tooltip title={t('tooltiptitle')}>
                            <InfoOutlinedIcon
                                style={{ marginLeft: '10px', color: '#666' }}
                                onClick={() => handleIconClick(infoContents.assignements)} />
                        </Tooltip>
                    </Box>
                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            rows={sortedChildren}
                            columns={columnsChildren}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            getRowId={(row) => row.email} />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Divider variant="middle" />
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h4">{t('assignsettings.licenses')}</Typography>
                        <Tooltip title={t('tooltiptitle')}>
                            <InfoOutlinedIcon
                                style={{ marginLeft: '10px', color: '#666' }}
                                onClick={() => handleIconClick(infoContents.licenses)} />
                        </Tooltip>
                    </Box>
                    <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            rows={sortedLicenses}
                            columns={columnsLicenses}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            getRowId={(row) => row.ID}
                            getRowClassName={getRowClassName} />
                    </div>
                </Grid>
                <Dialog
                    open={confirmUnassignDialog}
                    onClose={handleCloseConfirmUnassign}
                >
                    <DialogTitle>{t('assignsettings.confirmUnassignTitle')}</DialogTitle>
                    <DialogContent>
                        {t('assignsettings.confirmUnassignMessage', { type: deleteType.replace('remove', '') })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirmUnassign} color="primary">
                            {t('assignsettings.cancel')}
                        </Button>
                        <Button onClick={handleUnassign} color="primary" variant="contained">
                            {t('assignsettings.confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDialog} onClose={() => {
                    setDialogType("");
                    setOpenDialog(false);
                } }>
                </Dialog>
                <Dialog
                    open={dialogType.startsWith('add')}
                    onClose={() => { setDialogType(""); setOpenDialog(false); } }
                >
                    <DialogTitle>{dialogType === "addLicense" ? t('assignsettings.assignLicenseTitle') : t('assignsettings.assignPersonTitle')}</DialogTitle>
                    <DialogContent>
                        {dialogType === "addLicense" ? t('assignsettings.assignLicenseText') : t('assignsettings.assignPersonText')}
                        {dialogType === "addLicense" ?
                            <Select
                                fullWidth
                                value={selectedLicense}
                                onChange={(e) => setSelectedLicense(e.target.value)}
                            >
                                {childLicenses.map((license) => (
                                    <MenuItem key={license.ID} value={license.ID}>
                                        {license.ID}
                                    </MenuItem>
                                ))}
                            </Select>
                            :
                            <TextField
                                label={t('assignsettings.email')}
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setDialogType(""); setOpenDialog(false); } } color="primary">
                            {t('assignsettings.cancel')}
                        </Button>
                        <Button
                            onClick={handleAssign}
                            color="primary"
                            variant="contained"
                        >
                            {t('assignsettings.validate')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={openSnackbar}
                    onClose={() => setOpenSnackbar(false)}
                    autoHideDuration={6000}
                >
                    <Alert onClose={() => setOpenSnackbar(false)} severity="success" variant="filled">
                        {t('assignsettings.actionCompleted')}
                    </Alert>
                </Snackbar>
            </Grid>
        
            <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
                    <div style={{ padding: '20px', width: '300px', marginTop: '50px' }}>
                            {drawerContent}
                    </div>
            </Drawer>
        </div>
    );
}

export default Assignations;
